import React from 'react';

const Socials = () => {
  return (
    
<div>
  <a href="https://github.com/rshnn">
    <img src="github.svg" className="icon" />
  </a>
  
  <a href="https://www.linkedin.com/in/roshan-patel-ab8728a5/">
    <img src="linkedin.png" className="icon" />
  </a>

</div>

  );
}

export default Socials;